<template>
  <div class="console">
    <el-row :gutter="10">
      <el-col :span="18">
        <el-card shadow="never">
          <!--            标题-->
          <div class="interval">
            <span class="title">数据统计</span>
            <span class="describe">更新时间: 2021-12-08</span>
            <el-link class="title-link" type="primary" :underline="false">更多数据</el-link>
          </div>
          <!--            分组-->
          <div class="interval">
            <el-button-group>
              <el-button size="mini">零售商品统计</el-button>
              <el-button size="mini">销售统计</el-button>
              <el-button size="mini">其他统计</el-button>
            </el-button-group>
            <div class="bottom-line"></div>
          </div>
          <!--            卡片统计-->
          <el-row class="interval" :gutter="20">
            <el-col :span="8">
              <el-card>
                <div class="card-main-title">
                  <i :style="'color:'+$store.state.theme" class="iconfont icon-borderbottom-fill"></i>&nbsp;&nbsp;制造商</div>
                <div class="card-stat-count">5个</div>
                <div class="describe">统计时间：2021-12-08</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card>
                <div class="card-main-title">
                  <i :style="'color:'+$store.state.theme" class="iconfont icon-borderbottom-fill"></i>&nbsp;&nbsp;供应商</div>
                <div class="card-stat-count">5个</div>
                <div class="describe">统计时间：2021-12-08</div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card>
                <div class="card-main-title">
                  <i :style="'color:'+$store.state.theme" class="iconfont icon-borderbottom-fill"></i>&nbsp;&nbsp;零售商</div>
                <div class="card-stat-count">5个</div>
                <div class="describe">统计时间：2021-12-08</div>
              </el-card>
            </el-col>
          </el-row>
          <!--            统计图-->
          <el-card class="interval echarts-card">
            <div slot="header">
              <span class="card-title">订单数量</span>
              <div style="float: right">
                <div>
                  <el-date-picker
                      size="mini"
                      v-model="value"
                      type="daterange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div id="main" style="height: 440px; width: 100%"></div>
          </el-card>
        </el-card>
      </el-col>
      <el-col :span="6">
        <!--          零售商月订单排名-->
        <el-card class="interval news" shadow="never">
          <span class="title">零售商月订单排名</span>
          <el-link icon="el-icon-refresh" :underline="false"></el-link>
          <el-link class="title-link" type="primary" :underline="false">更多</el-link>
          <ul>
            <li v-for="(item, index) in 6" :key="index">
              <div class="small-title">
                <div :style="'background-color:'+$store.state.theme"  class="serial-number">{{item}}</div>
                <span class="news-content">零售商{{ item }}</span>
                <span class="describe news-describe">订单数：128</span>
              </div>
            </li>
          </ul>
        </el-card>
        <!--          1月内将到期零售商-->
        <el-card class="interval news" shadow="never">
          <span class="title">1月内将到期零售商</span>
          <el-link icon="el-icon-refresh" :underline="false"></el-link>
          <el-link class="title-link" type="primary" :underline="false">更多</el-link>
          <ul>
            <li v-for="(item, index) in 6" :key="index">
              <div class="small-title">
                <div :style="'background-color:'+$store.state.theme" class="serial-number">{{item}}</div>
                <span class="news-content">零售商{{ item }}</span>
                <span class="describe news-describe">到期时间：2021-12-23</span>
              </div>
            </li>
          </ul>
        </el-card>
        <!--          订单异常列表-->
        <el-card class="interval news" shadow="never" style="margin-bottom: 10px">
          <span class="title">订单异常列表</span>
          <el-link icon="el-icon-refresh" :underline="false"></el-link>
          <el-link class="title-link" type="primary" :underline="false">更多</el-link>
          <ul>
            <li v-for="(item, index) in 5" :key="index">
              <div class="small-title">
                <div :style="'background-color:'+$store.state.theme" class="serial-number">{{item}}</div>
                <span class="news-content">订单{{ item }}</span>
                <span class="describe news-describe">异常：发货超时</span>
              </div>
            </li>
          </ul>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "console",
  watch: {
    /**
     * 监听侧边栏开关
     */
    '$store.state.isCollapse'() {
      let _this = this
      setTimeout(function () {
        _this.$echarts.init(document.getElementById('main')).dispose();
        _this.initECharts()
      }, 150)
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initECharts()
      window.onresize = () => {
        return (() => {
          this.$echarts.init(document.getElementById('main')).dispose();
          this.initECharts()
        })();
      };
    })
  },
  methods: {
    initECharts() {
      let myChart = this.$echarts.init(document.getElementById('main'));

      let option;
      option = {
        //显示详情
        tooltip: {
          trigger: 'axis'
        },
        //显示隐藏控制
        legend: {
          data: ['零售商1', '零售商2', '零售商3', '零售商4', '零售商5']
        },
        //边距调整
        grid: {
          left: '1%',
          right: '1%',
          bottom: '5%',
          top: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['12/01', '12/02', '12/03', '12/04', '12/05', '12-06', '12/07']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '零售商1',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            smooth: true
          },
          {
            name: '零售商2',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            smooth: true
          },
          {
            name: '零售商3',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410],
            smooth: true
          },
          {
            name: '零售商4',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320],
            smooth: true
          },
          {
            name: '零售商5',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            smooth: true
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style lang="scss">
.console {
  //卡片间隔
  .interval {
    margin-bottom: 20px;
  }

  //标题
  .title {
    font-size: 16px;
    padding-right: 5px;
  }

  //次标题
  .small-title {
    padding-right: 5px;
    .serial-number{
      display: inline-block;
      background-color: #2d8cf0;
      width: 16px;
      text-align: center;
      margin-right: 5px;
      border-radius: 50px;
      color: #e6e6e6;
      font-size: 12px
    }
    .news-content{
      font-size: 14px;
    }
    .news-describe {
      float: right;
      margin-top: 3px;
    }
  }

  //描述
  .describe {
    font-size: 12px;
    color: #909399;
  }

  //标题右侧按钮
  .title-link {
    float: right;
    font-size: 12px;
    line-height: 20px;
  }

  //卡片标题
  .card-title{
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
  }
  //卡片body中的标题
  .card-main-title{
    font-size: 16px;
    color: #606266;
    i{
      font-size: 18px;
      color: #2d8cf0;
    }
  }

  //卡片中的统计数量
  .card-stat-count{
    font-size: 34px;
    font-weight: bold;
  }

  //分组按钮底部分割线
  .bottom-line {
    width: 100%;
    margin-top: -1px;
    border-bottom: 1px solid #DCDFE6
  }

  .echarts-card {
    .el-card__header {
      padding: 7px 20px;
    }
  }

  .news {
    ul {
      padding-left: 0;

      li {
        list-style: none;
        margin: 10px 0;
      }
    }
  }

}

.t {
  font-size: 20px;
  color: red;
}
</style>
